/* OBJECT.CONTROL */

.o-control {
	margin: $dsu 0;
}

.o-control__heading {
	margin-bottom: $bsu;
}

.o-control__figcaption {
	@include ui-border(bottom);
	padding: $hsu 0;
}
/* TOOLS.MIXINS */

// Breakpoint mixin
// Uses $breakpoints variable set in _config.scss file
@mixin bp($size, $includeFixed: true) {

	@if ($isFixedLayout and $includeFixed) {
		@content;
	}

	@else if ($isFixedLayout == false) {

		@if (type-of($size) == "number") {

			@media (min-width: #{em($size)}) {
				@content;
			}
		}

		@else if (type-of($size) == "string") {

			@each $breakpoint in $breakpoints {

				// Get the name of the breakpoint.
				$alias: nth($breakpoint, 1);

				@if ($size == $alias) {
					$start: bpConfigValue($breakpoint, "start");

					@media (min-width: #{em($start)}) {
						@content;
					}
				}
			}
		}

		@else {
			@warn "Unfortunately, no value could be retrieved from '#{$size}'. Please make sure it is defined in '$breakpoints' map.";
		}
	}
}

@mixin bp-only($size, $includeFixed: true) {

	@if ($isFixedLayout and $includeFixed) {
		@content;
	}

	@else if ($isFixedLayout == false) {

		@if (type-of($size) == "number") {

			@media (min-width: #{em($size)}) {
				@content;
			}
		}

		@else if (type-of($size) == "string") {

			@each $breakpoint in $breakpoints {
				// Get the name of the breakpoint.
				$alias: nth($breakpoint, 1);

				@if ($size == $alias) {
					$start: bpConfigValue($breakpoint, "start");
					$end: bpConfigValue($breakpoint, "end");

					@media (min-width: #{em($start)}) and (max-width: #{em($end)}) {
						@content;
					}
				}
			}
		}

		@else {
			@warn "Unfortunately, no value could be retrieved from '#{$size}'. Please make sure it is defined in '$breakpoints' map.";
		}
	}
}

@mixin heading {
	font-family: $brand-font-family;
	font-weight: 500;
	letter-spacing: -0.03em;
	color: $aig-core-blue;
}

@mixin link-transition {
	transition: all 0.3s ease;
}

// Clearfix
@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

// Pseudo Element
@mixin pseudo-element {
	content: '';
	display: block;
	position: absolute;
}

// Reliable Flexbox fallback mixin
// 1. Unsupported
// 2. Old - iOS 6-, Safari 3.1-6
// 3. Old - Firefox 19- (buggy but mostly works)
// 4. Tweener - IE 10
// 5. New - Chrome
// 6. New, Spec - Opera 12.1, Firefox 20+
@mixin flex-extend {
	display: block;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	/* autoprefixer: off */
	display: flex;
}

@mixin flex-wrap {
	-webkit-flex-flow: row wrap;
	-ms-flex-flow: row wrap;
	/* autoprefixer: off */
	flex-wrap: wrap;
}

// Placeholder Text
@mixin placeholder-text ($color) {
	::-webkit-input-placeholder {
		color: $color;
	}
	:-moz-placeholder {
		color: $color;
	}
	:-ms-input-placeholder {
		color: $color;
	}
}

// Mixin to add square dimensions to an element i.e. the same width and height 
// Example: Create a square 40em high and wide
// @include tb_square ( 40em );
@mixin square($size) {
	width: $size;
	height: $size;
}

// Mixin to add circle dimensions to an element i.e. adds square dimensions and border-radius of (dimensions / 2)
// Example: Create a circle 40em high and wide
// @include tb_square ( 40em );
@mixin circle($size) {
	@include square($size);
	border-radius: ( $size / 2);
}

// UI Border mixin
$ui-border: 1px solid $base-ui-color;

@mixin ui-border($side) {
	@if ($side == 'all') {
		border: $ui-border
	} @else if ($side == 'top') {
		border-top: $ui-border;
	} @else if ($side == 'right') {
		border-right: $ui-border;
	} @else if ($side == 'bottom') {
		border-bottom: $ui-border;
	} @else if ($side == 'left') {
		border-left: $ui-border;
	}
}

$outline-default: #4d90fe;
@mixin focus-outline($color: $outline-default) {
	// Replicate Chrome's default focus style
	outline-color: $color;
	@if ($color == $outline-default) {
		@supports (outline-color: -webkit-focus-ring-color) {
			outline-color: -webkit-focus-ring-color;
		}
	}

	// IE11 and Edge don't support outline-style: auto
	outline-style: solid;
	outline-width: 2px;
	@supports(outline-style: auto) {
		outline-style: auto;
	}
}

/* UTILITY.ICONS */

// .iconf-* usage example
//-----------------------
/*
	<span class="iconf-facebook" aria-hidden="true"></span>
*/

@font-face {
	font-family: 'font-icons';
	src:url('/content/fonts/font-icons/fonts/font-icons.eot');
	src:url('/content/fonts/font-icons/fonts/font-icons.eot#iefix') format('embedded-opentype'),
		url('/content/fonts/font-icons/fonts/font-icons.ttf') format('truetype'),
		url('/content/fonts/font-icons/fonts/font-icons.woff') format('woff'),
		url('/content/fonts/font-icons/fonts/font-icons.svg#font-icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

// Chrome rendering bugfix - http://www.adtrak.co.uk/blog/font-face-chrome-rendering/
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: 'font-icons';
		src: url('/content/fonts/font-icons/fonts/font-icons.svg#font-icons') format('svg');
	}
}

// Use !important to prevent issues with browser extensions that change fonts
[data-iconf]:before,
[class*="iconf-"],
%iconf {
	font-family: 'font-icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	// Better Font Rendering
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[data-iconf]:before {
	content: attr(data-iconf);
}

// Paste list of icon classes from Icomoon below this line
.iconf-link:before {
  content: "\e912";
}

.iconf-printer:before {
  content: "\e911";
}

.iconf-aig:before {
	content: "\e900";
}

.iconf-arrow-up:before {
	content: "\e901";
}

.iconf-arrow-down:before {
	content: "\e902";
}

.iconf-arrow-left:before {
	content: "\e904";
}

.iconf-arrow-right:before {
	content: "\e903";
}

.iconf-info:before {
	content: "\e905";
}

.iconf-help:before {
	content: "\e90d";
}

.iconf-plus:before {
	content: "\e90e";
}

.iconf-minus:before {
	content: "\e90c";
}

.iconf-done:before {
	content: "\e910";
}

.iconf-delete:before {
	content: "\e90f";
}

.iconf-tick:before {
	content: "\e906";
}

.iconf-close:before {
	content: "\e907";
}

.iconf-calendar:before {
	content: "\e908";
}

.iconf-phone:before {
	content: "\e909";
}

.iconf-email:before {
	content: "\e90a";
}

.iconf-profile:before {
	content: "\e90b";
}
﻿.c-form__field:has(.c-antidote) {
	display: block;
}

.c-antidote {
	margin-top: 40px;
}

.antidote-medical-screening .antidote-modal-container {
	position: fixed;
	max-height: calc(100vh - 100px);
	overflow: auto;
}

.antidote-modal-header {
	h1 {
		@include heading;
	}
}

.antidote-modal-container {
	.form {
		input[type="text"] {
			@include font-size('kilo');
			width: 100%;
			padding: 15px 30px;
			border: 1px solid $base-ui-color;
			color: $dark-grey;
		}
	}

	.button {
		@extend .o-btn;
		@extend .o-btn--secondary;
		font-size: 16px;
	}

	.button--primary {
		color: $white;
		background: $blue;

		&:focus,
		&:hover {
			color: $white;
			background: $mid-blue;
		}
	}

	.button--small {
		line-height: 1.5;
		border-color: $mid-grey;
		color: $mid-grey;
	}
}

.antidote-medical-screening .button-toolbar--in-list {
	margin-top: -3px;
}

.temp-disclaimer {
	margin-top: $bsu;
	margin-right: $bsu;

	&, p {
		font-size: 14px;
		font-style: italic;
	}
}

﻿/* CONTROL.ACCORDION */

.c-accordion__item {

}

.c-accordion__header {
	position: relative;
	padding-left: 35px;
}

.c-accordion__icon {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);

	b {
		position: absolute;
		transition: transform 0.2s ease;
		left: 0;
		width: 22px;
		height: 4px;
		background-color: $brand;
	}

	b:first-child {
		transform: rotate(-90deg);
	}

	.is-expanded & b:first-child {
		transform: rotate(0);
	}
}

.c-accordion__body {
	display: none;

	.is-expanded &{
		display: block;
	}
}
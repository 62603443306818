﻿/* UTILITY.MARGINS */

@use "sass:math";

// Config
//-------
// Default size set on _config.scss
// Only enable size variants you need to use to avoid bloat

$margin--default: $bsu !default;
$margin--tiny: math.div($margin--default, 4);
$margin--small: math.div($margin--default, 2);
$margin--large: $margin--default * 2;
$margin--huge: $margin--default * 4;

$enable-margins--default: true;
$enable-margins--none: true;
$enable-margins--tiny: true;
$enable-margins--small: true;
$enable-margins--large: false;
$enable-margins--huge: false;


@if ($enable-margins--default == true) {

	.u-m { margin: rem($margin--default) !important; }
	.u-mt { margin-top: rem($margin--default) !important; }
	.u-mr { margin-right: rem($margin--default) !important; }
	.u-mb { margin-bottom: rem($margin--default) !important; }
	.u-ml { margin-left: rem($margin--default) !important; }
	.u-mh { margin-right: rem($margin--default) !important; margin-left: rem($margin--default) !important; }
	.u-mv { margin-top: rem($margin--default) !important; margin-bottom: rem($margin--default) !important; }
}


@if ($enable-margins--tiny == true) {

	.u-m--tiny { margin: rem($margin--tiny) !important; }
	.u-mt--tiny { margin-top: rem($margin--tiny) !important; }
	.u-mr--tiny { margin-right: rem($margin--tiny) !important; }
	.u-mb--tiny { margin-bottom: rem($margin--tiny) !important; }
	.u-ml--tiny { margin-left: rem($margin--tiny) !important; }
	.u-mh--tiny { margin-right: rem($margin--tiny) !important; margin-left: rem($margin--tiny) !important; }
	.u-mv--tiny { margin-top: rem($margin--tiny) !important; margin-bottom: rem($margin--tiny) !important; }
}


@if ($enable-margins--small == true) {

	.u-m--small { margin: rem($margin--small) !important; }
	.u-mt--small { margin-top: rem($margin--small) !important; }
	.u-mr--small { margin-right: rem($margin--small) !important; }
	.u-mb--small { margin-bottom: rem($margin--small) !important; }
	.u-ml--small { margin-left: rem($margin--small) !important; }
	.u-mh--small { margin-right: rem($margin--small) !important; margin-left: rem($margin--small) !important; }
	.u-mv--small { margin-top: rem($margin--small) !important; margin-bottom: rem($margin--small) !important; }
}


@if ($enable-margins--large == true) {

	.u-m--large { margin: rem($margin--large) !important; }
	.u-mt--large { margin-top: rem($margin--large) !important; }
	.u-mr--large { margin-right: rem($margin--large) !important; }
	.u-mb--large { margin-bottom: rem($margin--large) !important; }
	.u-ml--large { margin-left: rem($margin--large) !important; }
	.u-mh--large { margin-right: rem($margin--large) !important; margin-left: rem($margin--large) !important; }
	.u-mv--large { margin-top: rem($margin--large) !important; margin-bottom: rem($margin--large) !important; }
}


@if ($enable-margins--huge == true) {

	.u-m--huge { margin: rem($margin--huge) !important; }
	.u-mt--huge { margin-top: rem($margin--huge) !important; }
	.u-mr--huge { margin-right: rem($margin--huge) !important; }
	.u-mb--huge { margin-bottom: rem($margin--huge) !important; }
	.u-ml--huge { margin-left: rem($margin--huge) !important; }
	.u-mh--huge { margin-right: rem($margin--huge) !important; margin-left: rem($margin--huge) !important; }
	.u-mv--huge { margin-top: rem($margin--huge) !important; margin-bottom: rem($margin--huge) !important; }
}


@if ($enable-margins--none == true) {

	.u-m--0 { margin: 0 !important; }
	.u-mt--0 { margin-top: 0 !important; }
	.u-mr--0 { margin-right: 0 !important; }
	.u-mb--0 { margin-bottom: 0 !important; }
	.u-ml--0 { margin-left: 0 !important; }
	.u-mh--0 { margin-right: 0 !important; margin-left: 0 !important; }
	.u-mv--0 { margin-top: 0 !important; margin-bottom: 0 !important; }
}
﻿// Default Variables
// '\2022' outputs ascii character '•'

$slick-arrow-size: 30px;
$slick-arrow-color: white;
$slick-dot-size: 50px !default;
$slick-dot-character: '\2022';
$slick-dot-color: white;
$slick-dot-color-active: $slick-dot-color;
$slick-opacity-default: 0.8;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.5;
$slick-opacity-disabled: 0;

// Slider
.slick-list {
	.slick-loading & {
		background: #fff;
	}
}

// Arrows
// These utilise the icon font
.slick-prev,
.slick-next {
	position: absolute;
	top: 50%;
	z-index: $z-slick-arrows;
	display: block;
	height: $slick-arrow-size;
	width: $slick-arrow-size;
	padding: 0;
	border: none;
	outline: none;
	font-family: 'font-icons';
	color: transparent;
	cursor: pointer;
	background: transparent;
	transform: translateY(-50%);

	&:hover,
	&:focus {
		outline: none;
		color: transparent;
		background: transparent;

		&:before {
			opacity: $slick-opacity-on-hover;
		}
	}

	&.slick-disabled:before {
		opacity: $slick-opacity-disabled;
	}

	&:before {
		opacity: $slick-opacity-default;
		font-size: $slick-arrow-size;
		line-height: 1;
		color: $slick-arrow-color;
		transition: opacity 0.2s linear;
	}
}

.slick-prev {
	@extend .iconf-arrow-left;
	left: rem($hsu);

	[dir="rtl"] & {
		@extend .iconf-arrow-right;
		left: auto;
		right: rem($hsu);
	}
}

.slick-next {
	@extend .iconf-arrow-right;
	right: rem($hsu);

	[dir="rtl"] & {
		@extend .iconf-arrow-left;
		left: rem($hsu);
		right: auto;
	}
}

// Dots
.slick-dots {
	position: absolute;
	bottom: rem($hsu);
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;

	li {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 20px;
		padding: 0;
		margin: 0 5px;
		cursor: pointer;

		button {
			display: block;
			height: 20px;
			width: 20px;
			padding: 5px;
			border: 0;
			outline: none;
			font-size: 0px;
			line-height: 0px;
			color: transparent;
			cursor: pointer;
			background: transparent;

			&:hover,
			&:focus {
				outline: none;

				&:before {
					opacity: $slick-opacity-on-hover;
				}
			}

			&:before {
				content: $slick-dot-character;
				position: absolute;
				top: 0;
				left: 0;
				opacity: $slick-opacity-not-active;
				width: 20px;
				height: 20px;
				font-size: $slick-dot-size;
				text-align: center;
				line-height: 20px;
				color: $slick-dot-color;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				transition: opacity 0.2s linear;
			}
		}

		&.slick-active button:before {
			opacity: $slick-opacity-default;
			color: $slick-dot-color-active;
		}
	}
}
/* COMPONENT.HERO */

.c-hero {
	position: relative;
	height: 150px;
	margin-bottom: 20px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	@include bp('medium') {
		height: 200px;
		margin-bottom: 30px;
	}

	@include bp('large') {
		height: 360px;
		margin-bottom: 50px;
	}

	.site-w.type-homepage & {
		&:after {
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			width: 16px;
			background-color: #DA1710;


			@include bp('large') {
				width: 24px;
			}
		}
	}
}

.c-hero__headings {
	position: absolute;
	width: 100%;
	max-width: $layout-wrapper-max-width;
	padding: 0 $bsu;
	text-align: center;
}

.c-hero__heading {
	@include heading;
	font-weight: 400;
	color: $white;
}

.c-hero__sub-heading {
	@include font-size('h4');
	font-family: $brand-font-family--bold;
	text-transform: uppercase;
	color: $white;
}
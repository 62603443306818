/* CONTROL.RTE */

.mce-content-body,
.c-rte {
	@include clearfix;

	& + & {
		margin-top: $bsu;
	}

	> *:last-child {
		margin-bottom: 0;
	}

	h2, h3, h4, h5, h6 {
		@include heading;
		margin-bottom: 0.5em;
	}

	hr {
		@include ui-border(top);
		border: 0;
		height: 0;
		clear: both;
		margin: $bsu 0;
	}

	a {
		color: $aig-cobalt;

		&:focus,
		&:hover {
			color: $aig-core-blue;
		}
	}

	// Lists
	> ul,
	> ol {
		padding: 0 0 0 1em;
		margin: 1em 0;

		ul,
		ol {
			margin: 0.25em 0;
		}

		ul li {
			list-style-type: circle;
		}

		ol li {
			list-style-type: lower-roman;
		}
	}

	> ul > li {
		list-style-type: disc;
	}

	> ol > li {
		list-style-type: decimal;
	}
	// Tables
	table {
		border-collapse: collapse;
		width: 100%;
		min-width: 480px;
		clear: both;
		margin-bottom: $bsu;

		p {
			margin: 0;
		}

		&[style*="width"] {
			min-width: 0;
		}

		&[style*="float: left"] {
			margin-right: $bsu;
		}

		&[style*="float: right"] {
			margin-left: $bsu;
		}
	}

	th,
	thead td,
	tfoot td {
		font-weight: bold;
	}

	th,
	td {
		@include ui-border(all);
		padding: $hsu $bsu;
	}

	td {
		vertical-align: top;
	}

	caption {
		@include ui-border(bottom);
		caption-side: bottom;
		padding: $hsu 0;
		text-align: left;
	}
}

// RTE figure - shared styling across macros
.c-rte__figure {
	margin: $bsu auto;

	&.u-float--left {
		clear: left;

		@include bp('small') {
			margin-top: 0;
			margin-right: $bsu;
			margin-left: 0;
		}
	}

	&.u-float--right {
		clear: right;

		@include bp('small') {
			margin-top: 0;
			margin-right: 0;
			margin-left: $bsu;
		}
	}
}

.c-rte__figcaption {
	@include ui-border(bottom);
	padding: $hsu 0;
}

.c-rte {
	.section {
		margin: $dsu 0;
	}

	.section-title {
		margin-bottom: 7px;
	}
	.section-item {
		position: relative;
		margin-bottom: 5px;
	}

	.section-item-title {
		font-weight: $weight--bold;
	}

	@include bp('medium') {
		.section-item {
			padding-left: 150px;
		}
		.section-item-title {
			position: absolute;
			left: 0;
			top: 0px;
		}
	}
}
﻿/* COMPONENT.ANNOUNCEMENT */

.c-announcement {
	display: flex;
	align-items: stretch;

	margin: 40px 0;
	&:first-child {
		margin-top: 0;
	}

	&.c-announcement--default {
		.c-announcement__icon {
			background: $blue;
			color: $white;
		}

		.c-announcement__text {
			box-shadow: inset 0 0 0 1px $blue;

			color: $body-color;
			a {
				color: $aig-cobalt;

				&:focus,
				&:hover {
					color: $aig-core-blue;
				}
			}
		}
	}

	&.c-announcement--westpac {
		background: $dark-grey;
		color: $white;

		.c-announcement__text {
			a {
				color: inherit;
				text-decoration: underline;
			}
		}
	}

	&.c-announcement--bnz {
		align-items: center;

		background: $bnz-blue;
		border-top: 4px solid $bnz-orange;

		.c-announcement__icon {
			color: $bnz-orange;
		}
		.c-announcement__text {
			padding-left: 0;
			color: $white;
			a {
				color: inherit;
				text-decoration: underline;
			}
		}
	}

	&.c-announcement--warning {
		$announcement-warning-slash-size: 100px;

		padding: 10px;
		background: $announcement-warning;
		background: repeating-linear-gradient(-45deg, $announcement-warning 0px, $announcement-warning $announcement-warning-slash-size, $white $announcement-warning-slash-size, $white ($announcement-warning-slash-size * 2));

		.c-announcement__icon {
			background: $dark-grey;
			color: $white;
		}

		.c-announcement__text {
			box-shadow: inset 0 0 0 1px $dark-grey;
			background: $white;
			color: $body-color;
			a {
				color: $aig-cobalt;

				&:focus,
				&:hover {
					color: $aig-core-blue;
				}
			}
		}
	}
}

.c-announcement__icon {
	display: block;

	padding: 20px;
	font-size: 40px;

	&::before {
		display: block;
		@extend %iconf;
		@extend .iconf-info, :before;
	}
}

.c-announcement__text {
	flex-grow: 1;

	padding: 25px;
	@include font-size("kilo");
	font-weight: bold;

	> :last-child {
		margin-bottom: 0;
	}
}

/* OBJECT.FLEX-PAIR */

// Uses flexbox to align two elements to start (top), center (middle), or end (bottom)
// More info: https://css-tricks.com/useful-flexbox-technique-alignment-shifting-wrapping/

// .o-flex-pair example usage
//---------------------------
/*
	<header class="o-flex-pair">
		<h2 class="o-flex-pair__major">@Model.Text</h2>
		<a class="o-flex-pair__minor" href="@Model.Link.Url">@Model.Link.Name</a>
	</header>
*/

/* begin if */
@if $use-flex-pair {

	.o-flex-pair {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.o-flex-pair--start {
		align-items: flex-start;
	}

	.o-flex-pair--end {
		align-items: flex-end;
	}

	.o-flex-pair__major {
		flex-grow: 1;
	}

	.o-flex-pair__minor {
		white-space: nowrap;
	}
}
/* endif */
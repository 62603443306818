﻿/* MACRO.VIDEO */

@use "sass:math";

.m-video {
}

.m-video__embed {
	padding-bottom: percentage(math.div(9,16));
}

.m-video__figcaption {
}
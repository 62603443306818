﻿/* BASE.TYPOGRAPHY */

@use "sass:math";

$weight--light: 300;
$weight--normal: 400;
$weight--medium: 500;
$weight--semibold: 600;
$weight--bold: 700;

html {
	font-family: $base-font-family;
	font-size: #{math.div($base-font-size, 16px) * 1em};
	line-height: lh($base-line-height, $base-font-size);
	color: $body-color;
}

p {
	margin-top: 0;
}

@mixin font-size-helper($index) {
	// Only intended for use in this file

	@each $bp, $size in $index {
		@if $bp == null {
			font-size: rem($size);

			@if map-has-key($index, "lh") {
				@if map-has-key($index, "large") {
					line-height: lh(map-get($index, "lh"), map-get($index, "large"));
				} @else {
					line-height: lh(map-get($index, "lh"), $size);
				}
			}

		} @else if $bp != "lh" {
			@include bp($bp) {
				font-size: rem($size);
			}
		}
	}
}

@mixin font-size($level) {
	$size: map-get($all-font-sizes, $level);

	@include font-size-helper($size);
}

@mixin font-utility-size-helper($name, $config) {
	// Only intended for use in this file

	@each $bp, $size in $config {
		@if $bp == null {
			font-size: rem($size);

			@if map-has-key($config, "lh") {
				@if map-has-key($config, "large") {
					line-height: lh(map-get($config, "lh"), map-get($config, "large"));
				} @else {
					line-height: lh(map-get($config, "lh"), $size);
				}
			}

		} @else if $bp != "lh" {
			@include bp($bp) {
				font-size: rem($size);
			}
		}
	}
}

@each $type, $config in $headers {
	.u-#{$type},
	.c-rte #{$type} {
		@include font-utility-size-helper($type, $config);
	}
}


/* begin if */
@if $use-font-helpers {
	@each $class, $config in $font-sizes {
		.u-#{$class} {
			@include font-utility-size-helper($class, $config);
		}
	}
}
/* end if */
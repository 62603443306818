/* OBJECTS */

// Config
//-------
// Re-usable structural elements, should not be edited or used as a hook for further styling
// Configure which objects to use below
// Specific configuration of object properties etc. found in their respective file

$use-grid--center: false;
$use-grid--rev: true;
$use-btn: true;
$use-btn--secondary: true;
$use-btn--light: true;
$use-btn--bordered: true;
$use-btn-sizes: true;
$use-flex-pair: true;

//-------

.o-heading {
	@include heading;
}

.o-icon-wrapper {
	position: relative;
	display: block;

	&:before {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

.o-input-combo {
	display: table;
	width: 100%;
}

.o-input-combo__input,
.o-input-combo__btn {
	display: table-cell;
	vertical-align: middle;
}

.o-input-combo__input {
	width: 100%;
}

.o-input-combo__btn {
	width: 1%;
}

@import "object.list";
@import "object.control";
@import "object.figure";
@import "object.fit";
@import "object.rotator";
@import "object.embed";
@import "object.grid";
@import "object.btn";
@import "object.flex-pair";
/* COMPONENT.NAVIGATION */

@use "sass:math";

// Main Navigation
$navigation-bg: $white;
$active-border-color: $brand;
$active-border-width: 3px;

.c-navigation {
	display: none;
	background: $aig-core-blue;
		z-index: 2;

	&.is-expanded {
		display: block;
	}

	@include bp('large') {
		position: relative;
		display: block;
		//padding: 22px 0 23px;

		&.is-sticky {
			transition: all 0.3s ease-in;
			position: fixed;
			width: 100%;
			left: 0;
			top: 0;
		}
	}
}

.c-navigation__wrapper {

	@include bp('large') {
		max-width: rem($layout-wrapper-max-width);
		padding: 0 rem($bsu);
		margin: 0 auto;
	}
}

.c-navigation__items {
	padding-top: rem(math.div($hsu, 2));

	@include bp('large') {
		width: 100%;
		display: table;
		padding: 0;
	}
}

.c-navigation__item {
	@include ui-border('bottom');
	position: relative;
	border-color: $white;
	&.is-expanded:before {
		@include pseudo-element;
		left: 0;
		top: 0;
		width: $active-border-width;
		height: 100%;
		background-color: $active-border-color;

		@include bp('large') {
			display: none;
		}
	}

	@include bp('large') {
		position: static;
		display: table-cell;
		padding: 22px 0 23px;
		width: 1%;
		border: none;
		text-align: center;
		vertical-align: bottom;
	}
}

.c-navigation__item--isite {
	padding: rem($bsu);
	padding-left: rem($bsu + $active-border-width);
}

.c-navigation__link {
	//@include link--plain;
	display: block;
	padding: rem($hsu) rem($lsu) rem($hsu) rem($bsu + $active-border-width);
	font-family: $brand-font-family;
	font-weight: $weight--medium;
	text-transform: uppercase;
	color: $white;

	@include bp('large') {
		display: inline-block;
		padding: 5px 0;
		border-bottom: 2px solid transparent;
		font-size: 18px;
		color: $white;

		.c-navigation__item:hover & {
			border-color: $white;
		}
	}
}

.c-navigation__link--home {
	font-size: rem(20px);

	@include bp('large') {
		line-height: 1;
	}
}

.c-navigation__expander {
	position: absolute;
	right: rem($bsu * 1.5);
	top: rem($hsu + 2);

	@include bp('large') {
		display: none;
	}
}


//NavigationDropDown
.c-navigation-dropdown__items{
	display:none;
	padding: rem($hsu) 0 rem($hsu) rem($bsu + $active-border-width);
	width:100%;
	background: $navigation-bg;
	.is-expanded > &{
		display:block;
		z-index: 2;
	}
	@include bp('large') {
		position:absolute;
		padding: 0;
		top: 100%;
	}
}
.c-navigation-dropdown__item {
	display: inline-block;
	width: 100%;
	margin-bottom: rem($hsu);

	@include bp('large') {
		@include ui-border('bottom');
		margin: 0;
	}
}

.c-navigation-dropdown__link {
	display: block;
	padding: rem(math.div($hsu, 2)) 0;
	color: $brand;
	&:before {
		display: none;
	}

	@include bp('large') {
		position: relative;
		padding: rem($hsu) rem($dsu) rem($hsu) rem($bsu);
		font-size: rem(14px);
		font-weight: $weight--bold;
		text-transform: uppercase;

		&:before {
			transition: all 0.3s ease;
			transform: translate(0, -50%);
			position: absolute;
			display: block;
			right: rem($bsu);
			top: 50%;
			color: $brand--alt;
			opacity: 0;
		}

		&:hover:before {
			right: rem($hsu);
			opacity: 1;
		}
	}
}
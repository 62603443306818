/* COMPONENT.RELATED-ITEMS */

.c-related-items {
}

.c-related-items__heading {
}

.c-related-items__intro {
	margin-bottom: $bsu;

	p:last-child {
		margin-bottom: 0;
	}
}

.c-related-item {
	margin-bottom: $qsu;
}

.c-related-item__link {
	position: relative;
	display: block;
	padding: $hsu $bsu;
	padding-left: $dsu + $bsu;
	color: $white;
	background: $brand;

	&:hover,
	&:focus {
		background: darken($brand, 10);
	}
}

.c-related-item__icon {
	position: absolute;
	left: $bsu;
	top: 50%;
	transform: translateY(-50%);
}

.c-related-item__meta {
}
﻿/* UTILITY.PADDING */

@use "sass:math";

// Config
//-------
// Default size set on _config.scss
// Only enable size variants you need to use to avoid bloat

$padding--default: $bsu !default;
$padding--tiny: math.div($padding--default, 4);
$padding--small: math.div($padding--default, 2);
$padding--large: $padding--default * 2;
$padding--huge: $padding--default * 4;

$enable-paddings--default: true;
$enable-paddings--none: true;
$enable-paddings--tiny: false;
$enable-paddings--small: false;
$enable-paddings--large: true;
$enable-paddings--huge: false;


@if ($enable-paddings--default == true) {

	.u-p { padding: rem($padding--default) !important; }
	.u-pt { padding-top: rem($padding--default) !important; }
	.u-pr { padding-right: rem($padding--default) !important; }
	.u-pb { padding-bottom: rem($padding--default) !important; }
	.u-pl { padding-left: rem($padding--default) !important; }
	.u-ph { padding-right: rem($padding--default) !important; padding-left: rem($padding--default) !important; }
	.u-pv { padding-top: rem($padding--default) !important; padding-bottom: rem($padding--default) !important; }
}


@if ($enable-paddings--tiny == true) {

	.u-p--tiny { padding: rem($padding--tiny) !important; }
	.u-pt--tiny { padding-top: rem($padding--tiny) !important; }
	.u-pr--tiny { padding-right: rem($padding--tiny) !important; }
	.u-pb--tiny { padding-bottom: rem($padding--tiny) !important; }
	.u-pl--tiny { padding-left: rem($padding--tiny) !important; }
	.u-ph--tiny { padding-right: rem($padding--tiny) !important; padding-left: rem($padding--tiny) !important; }
	.u-pv--tiny { padding-top: rem($padding--tiny) !important; padding-bottom: rem($padding--tiny) !important; }
}


@if ($enable-paddings--small == true) {

	.u-p--small { padding: rem($padding--small) !important; }
	.u-pt--small { padding-top: rem($padding--small) !important; }
	.u-pr--small { padding-right: rem($padding--small) !important; }
	.u-pb--small { padding-bottom: rem($padding--small) !important; }
	.u-pl--small { padding-left: rem($padding--small) !important; }
	.u-ph--small { padding-right: rem($padding--small) !important; padding-left: rem($padding--small) !important; }
	.u-pv--small { padding-top: rem($padding--small) !important; padding-bottom: rem($padding--small) !important; }
}


@if ($enable-paddings--large == true) {

	.u-p--large { padding: rem($padding--large) !important; }
	.u-pt--large { padding-top: rem($padding--large) !important; }
	.u-pr--large { padding-right: rem($padding--large) !important; }
	.u-pb--large { padding-bottom: rem($padding--large) !important; }
	.u-pl--large { padding-left: rem($padding--large) !important; }
	.u-ph--large { padding-right: rem($padding--large) !important; padding-left: rem($padding--large) !important; }
	.u-pv--large { padding-top: rem($padding--large) !important; padding-bottom: rem($padding--large) !important; }

}


@if ($enable-paddings--huge == true) {

	.u-p--huge { padding: rem($padding--huge) !important; }
	.u-pt--huge { padding-top: rem($padding--huge) !important; }
	.u-pr--huge { padding-right: rem($padding--huge) !important; }
	.u-pb--huge { padding-bottom: rem($padding--huge) !important; }
	.u-pl--huge { padding-left: rem($padding--huge) !important; }
	.u-ph--huge { padding-right: rem($padding--huge) !important; padding-left: rem($padding--huge) !important; }
	.u-pv--huge { padding-top: rem($padding--huge) !important; padding-bottom: rem($padding--huge) !important; }
}


@if ($enable-paddings--none == true) {

	.u-p--0 { padding: 0 !important; }
	.u-pt--0 { padding-top: 0 !important; }
	.u-pr--0 { padding-right: 0 !important; }
	.u-pb--0 { padding-bottom: 0 !important; }
	.u-pl--0 { padding-left: 0 !important; }
	.u-ph--0 { padding-right: 0 !important; padding-left: 0 !important; }
	.u-pv--0 { padding-top: 0 !important; padding-bottom: 0 !important; }
}
/* OBJECT.BTN */

// Config
//-------
$btn-default-font-size: 24px;
$btn-default-height: 60px;

.o-btn {
	display: inline-block;
	padding: 0 40px;
	border: 1px solid transparent;
	font-family: $brand-font-family;
	font-size: $btn-default-font-size;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	line-height: lh($btn-default-height, $btn-default-font-size);
	vertical-align: middle;
	white-space: nowrap;
	color: $white;
	background: $blue;
	cursor: pointer;
	transition: all 0.3s ease;

	&.is-selected {
		color: $dark-blue;
		background: $light-blue;
	}

	&:hover {
		color: $white;
		background: $mid-blue;
	}

	&[disabled] {
		color: $mid-grey;
		background: $light-grey;
		cursor: not-allowed;

		&:hover {
			color: $mid-grey;
			background: $light-grey;
		}
	}
}

.o-btn--full {
	display: block;
	width: 100%;
}

.o-btn--reset {
	padding: 0;
	border: none;
	background: 0;
}

.o-btn--secondary {
	border-color: $blue;
	color: $blue;
	background: $white;

	&.is-selected {
		border-color: $dark-blue;
		color: $dark-blue;
	}

	&[disabled] {
		border-color: transparent;
	}

	&:hover {
		border-color: transparent;
		color: $white;
		background: $mid-blue;
	}
}

.o-btn--wrap {
	padding: $hsu $bsu;
	font-size: 16px;
	white-space: pre-wrap;
	line-height: 1.5;
}

.o-btn--wrap-mobile {
	@include bp-only('extra-small') {
		white-space: pre-wrap;
		line-height: 1;
		padding-top: $bsu;
		padding-bottom: $bsu;
	}
}

.o-btn--green {
	border-color: $success;
	color: $white;
	background: $success;
	&[disabled] {
		border-color: transparent;
	}

	&:hover {

		background: darken($success, 10%);
	}
}
.o-btn--green-outline {
	border-color: $success;
	color: $success;
	background: $white;

	&:hover {
		background: darken($success, 10%);
	}
}
/* COMPONENT.HEADER */

@use "sass:math";

$mobile-button-size: 45px;
$mobile-button-color: $blue;
$mobile-button--hover-color: $mid-blue;
$mobile-button-font-size: 25px;
$mobile-button-padding: math.div(($mobile-button-size - $mobile-button-font-size), 2);

.c-header {
	position: relative;
	/*z-index: $z-header;*/
	background: $white;
}

.c-header__wrapper {
	@include flex-extend;
	align-items: center;
	justify-content: space-between;
	padding: $bsu;
	padding-right: 75px;

	@include bp('large') {
		padding-right: $bsu;
	}
}

.c-header__text {
	padding: $bsu;
	text-align: center;
	background: $white;
	@include bp('large') {
		width: calc(100% - 260px);
		padding: 0 $bsu;
		background-color: inherit;
	}
}

.c-header__heading {
	@include font-size('giga');
	display: block;
	font-family: $brand-font-family--bold;
	text-transform: uppercase;
}

.c-header__tagline {
	@include font-size('kilo');
	text-transform: uppercase;
}

@mixin logo($logo-width, $logo-height, $target-height) {
	$sprite-width: 130px;
	$width: math.div($logo-width, $logo-height) * $target-height;
	display: block;
	width: $width;
	height: $target-height;
	background-color: transparent;
	background-image: url('/Content/images/logo/header-logos.png');
	background-repeat: no-repeat;

	@if ($target-height != $logo-height) {
		background-size: ceil(percentage(math.div($sprite-width,$logo-width)));
	} @else {
		background-size: auto;
	}
}

.c-header__logo {
	@include logo(119px, 64px, 36px);
	background-position: 0 -36px;

	@include bp('large') {
		@include logo(119px, 64px, 64px);
		background-position: 0 -64px;
	}
}

.c-header__bank-logo {
	@include logo(130px, 53px, 31px);
	background-position: 0 -75px;

	@include bp('large') {
		@include logo(130px, 53px, 53px);
		background-position: 0 -128px;
	}
}

.c-header__bank-logo-managed {}

.c-header__bank-logo-figure {
	position: relative;
}

.c-header__bank-logo-image {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.c-header__mobile-buttons {
	position: absolute;
	right: $bsu;
	top: 15px;
	direction: rtl;

	@include bp('large') {
		display: none;
	}
}

.c-header__mobile-button {
	position: relative;
	width: $mobile-button-size;
	height: $mobile-button-size;
	padding: $mobile-button-padding 0;
	transition: all 0.3s ease-in-out;
}
// Nav Toggle
$nav-toggle-border-width: math.div($mobile-button-font-size,5);

.c-header__nav-toggle {

	b {
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, border-color 0.3s ease-in-out;
		position: absolute;
		left: $mobile-button-padding;
		display: block;
		width: $mobile-button-font-size;
		height: 0;
		border-top: $nav-toggle-border-width solid $mobile-button-color;

		&:nth-child(1) {
			top: $mobile-button-padding;
		}

		&:nth-child(2) {
			top: $mobile-button-padding + ($nav-toggle-border-width * 2);
		}

		&:nth-child(3) {
			top: $mobile-button-padding + ($nav-toggle-border-width * 4);
		}
	}

	&.is-expanded {

		b {
			&:nth-child(1) {
				transform: translateY(10px) rotate(45deg);
			}

			&:nth-child(2) {
				opacity: 0;
			}

			&:nth-child(3) {
				transform: translateY(-10px) rotate(-45deg);
			}
		}
	}

	&:hover,
	&.is-expanded {
		b {
			border-color: $mobile-button--hover-color;
		}
	}
}
// Search Toggle
.c-header__search-toggle {
	font-size: $mobile-button-font-size;
	color: $mobile-button-color;

	&:hover,
	&.is-expanded {
		color: $mobile-button--hover-color;
	}

	.iconf-search {
		position: absolute;
		left: $mobile-button-padding;
		top: $mobile-button-padding;
	}
}

.c-header__search {
	display: none;
	padding-top: rem($bsu);

	&.is-expanded {
		display: block;
	}

	@include bp('large') {
		display: block;
		float: right;
		width: rem(250px);
	}
}
/* COMPONENT.TOOLTIP */

/*
	<div class="c-tooltip js-tooltip">
		<button class="c-tooltip__trigger js-tooltip__trigger"></button>
		<div class="c-tooltip__body js-tooltip__body">
			<a href="#" class="c-tooltip__close iconf-close js-tooltip__close"></a>
			<p>Tooltip body</p>
		</div>
	</div>
 */

$tooltip-trigger-size: 16px;
$tooltip-arrow-size: 10px;
$tooltip-width: 320px;

.c-tooltip {
	display: inline-block;
	height: $tooltip-trigger-size;

	.c-tooltip__trigger {
		position: relative;
		display: inline-block;
		font-size: 16px;
		text-decoration: none;
		line-height: 1;
		color: $blue;
		cursor: pointer;

		&:focus,
		&:hover {
			color: $mid-blue;
		}

		&:focus:not(:focus-outline) {
			outline: none;
		}
	}

	.c-tooltip__body {
		position: absolute;
		z-index: $z-tooltip;
		display: none;
		width: $tooltip-width;
		max-width: calc(100% - #{$tooltip-trigger-size + $tooltip-arrow-size + $hsu + $dsu});
		padding: $bsu $dsu $bsu $bsu;
		margin-top: -($hsu + 3px);
		margin-left: $tooltip-arrow-size + $hsu;
		line-height: lh($base-line-height, $base-font-size);
		color: $white;
		background: $mid-blue;
		transform: translateX($tooltip-trigger-size) translateY(-50%);

		p {
			font-size: 16px;
			font-weight: 400;
		}

		&:before {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			left: -$tooltip-arrow-size;
			top: 50%;
			margin-top: -$tooltip-arrow-size;
			margin-left: 0;
			border-top: $tooltip-arrow-size solid transparent;
			border-right: $tooltip-arrow-size solid $mid-blue;
			border-bottom: $tooltip-arrow-size solid transparent;
			border-left: 0;
		}

		.c-tooltip__close + * {
			margin-top: 0;
		}

		> :last-child {
			margin-bottom: 0;
		}

		img {
			max-width: 100%;
		}

		a {
			color: $white;
			text-decoration: underline;
			font-weight: $weight--bold;
		}

		.c-tooltip__close {
			text-decoration: none;
		}
	}

	&.is-open {
		.c-tooltip__body {
			display: block;
		}
	}

	.c-tooltip__close {
		@include link-transition;
		position: absolute;
		right: $hsu;
		top: $hsu;
		font-size: 12px;
		color: $white;

		&:hover {
			text-decoration: none;
			color: rgba($white, 0.6);
		}
	}

	&.c-tooltip--left {
		.c-tooltip__body {
			margin-left: -($tooltip-arrow-size + $hsu);
			transform: translateX(-100%) translateY(-50%);

			&:before {
				left: auto;
				right: -$tooltip-arrow-size;
				border-right: 0;
				border-left: $tooltip-arrow-size solid $black;
			}
		}
	}
}
﻿/* MACRO.BLOCKQUOTE */

.m-quote {
}

.m-quote__text {
	color: $brand--alt;
	font-weight: $weight--bold;
}

.m-quote__citation {
	color: $brand--alt;
	font-weight: $weight--bold;
}
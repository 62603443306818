/* COMPONENT.FOOTER */

.c-footer {
	padding: 50px 0;
	background: $dark-grey;
}

.c-footer__wrapper  {
	position: relative;
}

.c-footer__links {
	padding-bottom: 30px;
	border-bottom: 1px solid $mid-grey;
}

.c-footer__links-item {
	float: left;
	display: inline-block;
	margin-right: 75px;
	margin-bottom: 10px;
}

.c-footer__link {
	font-weight: $weight--bold;
	color: $white;
}

.c-footer__legal {
	position: relative;
	padding-top: 40px;
	padding-left: 150px;
	color: $white;
}

.c-footer__legal-logo {
	position: absolute;
	left: 0;
	top: 42px;
	font-size: 56px;
}

.c-footer__legal-text {
	max-width: 820px;
	margin: 0;
	font-size: 14px;

	a {
		color: $white;
		text-decoration: underline;

		&:hover, &:focus {
			text-decoration: none;
		}
	}

	> :last-child {
		margin: 0;
	}
}

.c-footer__top {
	position: absolute;
	right: $bsu;
	top: -8px;

	.iconf-arrow-up {
		font-size: 40px;
		color: $white;
	}
}
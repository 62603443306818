/* COMPONENT.LAYOUT */

.c-layout-wrapper {
    max-width: em($layout-wrapper-max-width + ($bsu * 2));
    padding: 0 em($bsu);
    margin: 0 auto;

    .tb-fixed & {
        width: $layout-wrapper-max-width--ie;
    }
}

.c-layout-page {
    position: relative;
    z-index: $z-page;
}

.c-layout-main {
    padding-bottom: 50px;
}

.c-layout-header {
    margin-bottom: rem($dsu);

    h1 {
        margin-bottom: 0.3em;
    }
}

.c-layout-header__lede {
    font-size: 1.2em;
}

.c-layout-holding {
    min-height: calc(100vh - 305px);
    display: flex;

    .c-layout-holding__Wrapper {
        max-width: 620px;
        padding-top: 100px;
    }
}

/* COMPONENT.LEDE */

.c-lede {
	@include ui-border('bottom');
	padding-bottom: 20px;
	margin-bottom: 20px;
	text-align: center;

	@include bp('large') {
		padding-bottom: 50px;
		margin-bottom: 50px;
	}

	p {
		@include font-size('mega');
		max-width: 1100px;
		margin: 0 auto 1rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	a {
		color: $brand;

		&:focus,
		&:hover {
			color: $mid-blue;
		}
	}
}

.c-homepage__lede {
	@include heading;
	position: relative;
	padding-top: $bsu;
	margin-bottom: 30px;
	font-size: 20px;
	color: $dark-grey;

	&:before {
		@include pseudo-element;
		left: 0;
		top: 0;
		width: 50px;
		height: 3px;
		background: $mid-blue;
	}

	a {
		color: $aig-cobalt;

		&:focus,
		&:hover {
			color: $aig-core-blue;
		}
	}
}
﻿/* CONTROL.FORM */

.c-form {
}

.c-form__section {
	@include ui-border('bottom');
	padding-bottom: 35px;
	margin-bottom: 35px;

	@include bp('medium') {
		padding-bottom: 50px;
		margin-bottom: 50px;
	}
}

.c-form__fieldset {
	margin-bottom: 35px;

	@include bp('medium') {
		margin-bottom: 50px;
	}
}

.c-form__fieldset--child {

	& + & {
		margin-top: 50px;
	}
}

.c-form__legend {
	@include heading;
	@include font-size('h2');
	position: relative;
	display: block;
	padding-top: 0.75em;
	margin-bottom: 1em;
	color: $blue;

	@include bp('medium') {
		padding-top: 0.5em;
	}

	&.has-intro {
		margin-bottom: 0.2em;
	}

	&:before {
		@include pseudo-element;
		left: 0;
		top: 0;
		width: 50px;
		height: 3px;
		background: $mid-blue;
	}
}

.c-form__fieldset-intro {
	margin-bottom: 2.6667em;
	font-style: italic;
	color: $text-grey;

	p:last-child {
		margin-bottom: 0;
	}
}

.c-form__legend-note {
	@include font-size('kilo');
	display: block;
	margin-top: 1em;
	font-family: $base-font-family;
	font-weight: $weight--semibold;
	color: $text-grey;
}

.c-form__fields-col {
	max-width: 1000px;
}

.c-form__toggle-section {
	margin-bottom: 28px;
}

.c-form__dependant-btn {
	@include font-size('h4');
	position: relative;
	padding: 10px 0 10px 60px;
	margin-top: 20px;
	color: $blue;
	cursor: pointer;
	vertical-align: middle;

	&:focus:not(:focus-visible) {
		outline: none;
	}

	&:first-child {
		margin-right: 60px;
	}

	&:hover {
		color: $mid-blue;
	}
}

.c-form__dependant-btn--remove {
	@include link-transition;
	display: none;
	opacity: 0;
	color: $error;

	&.is-displayed {
		display: inline;
		opacity: 1;
	}

	&:hover {
		color: darken($error, 10);
	}
}

.c-form__dependant-btn-icon {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	font-size: 40px;
}

.c-form__field {
	margin-bottom: 20px;

	@include bp('medium') {
		@include flex-extend;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 28px;
	}
}

.c-form__field--align-top {
	align-items: flex-start;
}

.c-form__label {
	@include font-size('kilo');
	margin-bottom: $hsu;
	font-weight: $weight--semibold;

	@include bp('medium') {
		width: calc(50% - 15px);
	}
}

.c-form__option-label {
	@include font-size('kilo');
	color: $dark-grey;
}

.c-form__inputs {

	@include bp('medium') {
		width: calc(50% - 15px);
	}

	input[type="text"],
	input[type="email"],
	input[type="datetime-local"],
	input[type="date"],
	textarea,
	select {
		@include font-size('kilo');
		width: 100%;
		padding: 15px 30px;
		border: 1px solid $base-ui-color;
		color: $dark-grey;

		.has-error & {
			border-color: $error;
		}
	}

	select {
		/*-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;*/
	}

	textarea {
		display: block;
	}

	input.c-form__input--short {

		@include bp('large') {
			width: 50%;
		}
	}

	input.c-form__datepicker__input {
		padding-right: 19px;
	}
}

.c-form__checkbox-list-item {
	@include flex-extend;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
}

.c-form__checkbox-list-label {
	width: calc(100% - 60px);

	*:last-child {
		margin-bottom: 0;
	}
}

.c-form__radio-list {
	@include flex-extend;

	@include bp('large') {
		width: 50%;
	}
}

.c-form__radio-option {
	@include flex-extend;
	align-items: center;

	& + & {
		margin-left: $dsu;
	}
}

.c-form__radio-option-input {
	margin-right: 20px;
}

.c-form__datepicker {
	position: relative;

	@include bp('large') {
		width: 220px;
	}
}

.c-form__datepicker--with-time {
	@include bp('large') {
		width: 306px;
	}
}

.c-form__datepicker-icon {
	position: absolute;
	height: 30px;
	width: 30px;
	right: 20px;
	top: 50%;
	font-size: 24px;
	color: $blue;
	transform: translateY(-50%);
	pointer-events: none;
	background: #fff;
	padding: 3px;
	display: block;
	cursor: pointer;
}

.c-form__error {
	display: none;
	font-weight: $weight--bold;
	color: $error;

	.c-form__section.has-error &,
	.c-form__field.has-error & {
		display: block;
		padding-top: $hsu;
	}

	.c-form__field.has-error .c-form__checkbox-list + & {
		padding-top: $hsu + $qsu;
	}
}

.c-form-message-label {
	position: relative;
	margin-bottom: $bsu;

	@include bp('medium') {
		margin-right: $bsu;
		margin-bottom: 0;
	}

	.c-form-message-label__icon {
		position: absolute;
		display: block;
		left: $bsu;
		top: 17px;
		color: $success;
		font-size: 33px;
	}

	&.c-form-message-label__success {
		padding: $bsu $bsu $bsu 70px;
		border: 1px solid $success;
	}

	&.c-form-message-label__error {
		padding: $bsu $bsu $bsu 70px;
		border: 1px solid $error;

		.c-form-message-label__icon {
			color: $error;
			border-color: $error;
		}
	}

	&.c-form-message-label--fees {

		@include bp('medium') {
			margin-right: 0;
			margin-bottom: $bsu;
		}
	}

	@include bp('large') {
		flex: 1 1 auto;
	}
}

.c-form-message-label__heading {
	@include font-size('kilo');
	margin-bottom: 0.25em;
	font-weight: $weight--semibold;

	span {
		color: $warning;
	}
}

.c-form-message-label__text {

	p:last-child {
		margin-bottom: 0;
	}
}

.c-form__screening {
	align-self: flex-start;

	@include bp('large') {
		flex: 1 0 200px;
		max-width: 200px;
	}

	&,
	input.o-btn {
		width: 100%;

		@include bp('medium') {
			width: auto;
		}
	}
}

.c-form__controls {
	.o-btn.is-disabled {
		opacity: 0.5;
		pointer-events: none;
		cursor: not-allowed, default;
	}

	.c-form__controls-block {
		text-align: right;
	}

	.c-form__helper-text {
		display: block;
		font-size: 14px;
		margin-top: 5px;
		font-weight: $weight--semibold;
	}
}

.c-form-print-icon {
	color: $brand--alt;
	font-size: 50px;
}

.c-form__screening__btn {
	background-color: #d1d0ce;
	color: #76777b;
}

.c-form__screening__text {
	font-size: 13px;
	margin-top: $hsu;
}
// Fake Inputs
// Extend for both radio and checkbox
%c-fake-option {
	// Hide, but still allow access through tabbing
	position: absolute !important;
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	overflow: hidden !important;
	padding: 0 !important;
	height: 1px !important;
	width: 1px !important;
	margin: -1px !important;

	&:checked + label {
		&:before {
			opacity: 1;
		}
	}

	&:focus-visible ~ %c-fake-option__display {
		@include focus-outline;
	}
}

%c-fake-option__display {
	position: relative;
	display: block;
	vertical-align: middle;
	width: 40px;
	height: 40px;
	border: 1px solid $base-ui-color;
	text-align: center;
	line-height: 40px;

	&:before {
		@include link-transition;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
	}
}

// Fake checkbox
.c-fake-checkbox {
	@extend %c-fake-option;
}

.c-fake-checkbox__display {
	@extend %c-fake-option__display;

	.has-error & {
		border-color: $error;
	}

	&:before {
		font-size: 24px;
		color: $blue;
	}
}

// Fake Radio
.c-fake-radio {
	@extend %c-fake-option;
}

.c-fake-radio__display {
	@extend %c-fake-option__display;
	border-radius: 40px;

	.has-error & {
		border-color: $error;
	}

	&:before {
		content: '';
		display: block;
		width: 20px;
		height: 20px;
		border-radius: 20px;
		background: $blue;
	}
}

// Loading Button
.o-btn--loader span {
	display: inline-block;
	animation: loading 1s 0s infinite;
}

.o-btn--loader span:nth-child(2) {
	animation-delay: 0.12s;
}

.o-btn--loader span:nth-child(3) {
	animation-delay: 0.24s;
}

.o-btn--loader span:nth-child(4) {
	animation-delay: 0.36s;
}

.o-btn--loader span:nth-child(5) {
	animation-delay: 0.48s;
}

.o-btn--loader span:nth-child(6) {
	animation-delay: 0.60s;
}

.o-btn--loader span:nth-child(7) {
	animation-delay: 0.72s;
}

@keyframes loading {
	0% {
		transform: translateY(0);
	}

	12% {
		transform: translateY(-2px);
	}

	24% {
		transform: translateY(0);
	}
}

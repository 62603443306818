﻿/* CONTROL.HEADING */

.c-heading {
	margin: $dsu 0 $bsu;
}

.c-heading__main {
}

.c-heading__link {
}
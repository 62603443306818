﻿/* BASE.PRINT */

.print-only {
	display: none !important;
}

@media print {
	* {
		color: black !important;
		box-shadow:none !important;
		text-shadow: none !important;
		filter:none !important;
		-ms-filter: none !important;
	}

	.c-header, .c-hero, .c-footer, .c-form-print-icon {
		display: none !important;
	}

	.print-header__table {
		margin-bottom: 2em;
	}

	.print-only {
		display: block !important;
	}
	.print-hidden {
		display: none !important;
	}
	.print-pb-before {
		page-break-before: always;
	}
}
/* COMPONENT.PATHWAYS */
.c-pathways {
	@include ui-border('top');
	padding-top: $bsu;
	margin-top: $bsu;

	@include bp('large') {
		padding-top: 30px;
		margin-top: 30px;
	}
}

.c-pathways__heading {
	margin-bottom: 40px;
}

.c-pathways__list {
	@include flex-extend;
	flex-wrap: wrap;
	justify-content: space-between;
}

.c-pathways__item {
	@include link-transition;
	display: block;
	margin-bottom: $bsu;
	width: 100%;
	text-align: center;
	padding: $hsu * 1.5 $bsu;
	color: $blue;
	border: 1px solid $blue;

	&:focus,
	&:hover {
		color: $white;
		background: $blue;
	}

	@include bp('medium') {
		width: 20%;
	}
}
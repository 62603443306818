/* COMPONENT.MEGAMENU */

@use "sass:math";

$megamemu__intro-width: 400px;

.c-megamenu {
	display: none;
	background: $white;

	&.is-expanded,
	.is-expanded > & {
		display: block;
	}

	&.is-expanded {
		box-shadow: 0 8px 6px -6px rgba($black, 0.2);
	}

	@include bp('large') {
		transition: max-height 0.5s ease-in-out;
		position: absolute;
		left: 0;
		display: block;
		width: 100%;
		max-height: 0;
		overflow: hidden;
		text-align: left;
		background: rgba(251,251,251, 0.98);
		box-shadow: 0 8px 6px -6px rgba($black, 0.2);

		&.is-expanded,
		.is-expanded > & {
			max-height: 500px;
		}

		.no-js .c-navigation__item:hover & {
			display: block;
		}
	}
}

.c-megamenu__wrapper {

	@include bp('large') {
		max-width: em($layout-wrapper-max-width);
		padding: 0 em($bsu);
		padding-top: rem($bsu * 3);
		padding-bottom: rem($bsu * 3);
		margin: 0 auto;

		.tb-fixed & {
			width: $layout-wrapper-max-width--ie;
		}
	}
}

.c-megamenu__intro {
	width: $megamemu__intro-width;
	float: left;
	padding-right: rem($lsu);
}

.c-megamenu__intro-heading {
	display: block;
	margin-bottom: rem($bsu);
	font-size: rem(24px);
}

.c-megamenu__items {
	padding: rem($hsu) 0 rem($hsu) rem($bsu + $active-border-width);

	@include bp('large') {
		width: calc(100% - #{$megamemu__intro-width});
		float: right;
		columns: 2;
		column-gap: $dsu;
		padding: 0;
	}

	@include bp('extra-large') {
		columns: 3;
	}
}

.c-megamenu__item {
	display: inline-block;
	width: 100%;
	margin-bottom: rem($hsu);

	@include bp('large') {
		@include ui-border('bottom');
		margin: 0;
	}
}

.c-megamenu__link {
	display: block;
	padding: rem(math.div($hsu, 2)) 0;

	&:before {
		display: none;
	}

	@include bp('large') {
		position: relative;
		padding: rem($hsu) rem($dsu) rem($hsu) rem($bsu);
		font-size: rem(14px);
		font-weight: $weight--bold;
		text-transform: uppercase;

		&:before {
			transition: all 0.3s ease;
			transform: translate(0, -50%);
			position: absolute;
			display: block;
			right: rem($bsu);
			top: 50%;
			color: $brand--alt;
			opacity: 0;
		}

		&:hover:before {
			right: rem($hsu);
			opacity: 1;
		}
	}
}
﻿/* PLUGINS.TABLESCROLL */

@if ($use-tablescroll == true) {

	.tablescroll {
		position: relative;
		overflow: hidden;
		clear: both;
		margin-bottom: rem($bsu);
		table {
			margin-bottom: 0;
		}
		&:before,
		&:after {
			@include pseudo-element;
			top: 0;
			width: 1%;
			height: 100%;
			display: none;
		}
		&.is-scrolling {
			&:before,
			&:after {
				display: block;
			}
		}
		&:before {
			box-shadow: rgba(#000, 0.5) 5px 0 20px;
			left: -1%;
		}
		&:after {
			box-shadow: rgba(#000, 0.5) -5px 0 20px;
			right: -1%;
		}
		&.is-end {
			&:after {
				display: none;
			}
		}
		&.is-start {
			&:before {
				display: none;
			}
		}
		&.is-end {
			&:after {
				display: none;
			}
		}
		.tablescroll-inner {
			width: 100%;
		}
		&.is-scrolling .tablescroll-inner {
			overflow-x: scroll;
		}
	}
}